import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'



class Whatis extends React.Component {


  render() {

    return (
      <section className="whatis pt-100 mb-0 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              
              <h2 className="heading-h2"><span className="heading-h3"><span className='bluecolor'>Fully customizable</span> White label</span>
                 Binance Clone Script</h2>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-5 col-md-12 left-side text-center" >
            <StaticImage
                  src="https://coinsclone.mo.cloudinary.net/images/binance-new/fully-customizable-white-label.png"
                  alt="Cuztomizable white label Binance Clone"
                  width={410}
                />
            </div>
            <div className="col-lg-7 col-md-12">
              <p className="pharagraph" >Our white-label solution will help entrepreneurs reach great heights quickly in a risk-free manner and will have the luxury of low investment as we
              </p>
              <p className="pharagraph">We provide premium white-label software and we use the latest technology tools to develop our Binance clone app.
              </p>
              <p className="pharagraph">Our software helps startups and entrepreneurs build a hassle-free exchange that overcomes any tough market condition and keeps them prevailing high. Besides, Our Binance clone software is open to customizations, so you can make desired changes in features, themes, designs, or whatever as per your business needs.</p>
              <p className="pharagraph mb-0" >Our Binance exchange script comes with an extraordinary UI and remarkable API. Also, our Binance exchange clone holds military-grade security features to avoid fraudulent activities on your platform.
              </p>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Whatis